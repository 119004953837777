.card {
  height: 100%;
  :global {
    border-radius: 10px;
    padding: 8px 16px 16px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);

    .ant-card-body {
      padding: 8px 0 0;
    }

    .ant-card-head-title {
      color: rgba(63, 71, 120, 1);
    }

    .ant-card-head {
      padding: 0 0 8px;
      height: auto;
      min-height: auto;
      h4 {
        color: #3f4778;
      }
    }
  }
}

.dashed {
  border: 1px dashed rgba(63, 71, 120, 30%);
  background: #f9fafd;
  :global {
    .ant-card-head-title {
      color: #3f4778;
    }
  }
}

.light {
  background: rgba(75, 96, 216, 0.03);
  border: none;
  :global {
    box-shadow: none;
    .ant-card-head-title {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.overlay {
  &::after {
    content: '';
    position: absolute;
    opacity: 0.4;
    background: #fff;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.withoutBottomPadding {
  padding: 8px 16px 0;
}

.withoutTitle {
  padding-top: 0px;
}

.withoutHeadBorder {
  :global {
    .ant-card-head {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
