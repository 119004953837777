@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;500;600;700&family=Roboto:wght@300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'golosUi';
  src: url('./fonts/golos-ui_bold.woff');
}

@font-face {
  font-family: 'golosUi';
  src: url('./fonts/golos-ui_regular.woff');
}

html,
body,
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

/*svg {*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

body {
  margin: 0;
  font-family: 'Golos Text', 'golosUi', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Обнуление*/
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.17rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.83rem;
}

h6 {
  font-size: 0.67rem;
}

body {
  background-color: #fff;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-collapse-header-text {
  font-size: 0.875rem;
}

.ant-collapse-header > .ant-collapse-header-text {
  font-size: 16px;
}

.monochromeTheme {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.leaflet-container {
  width: 100%;
  border-radius: 20px;
}

.leaflet-container img.leaflet-tile {
  mix-blend-mode: unset;
  width: 100%;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
  border-radius: 10px;
}

.disaster li {
  list-style-type: disc;
  margin: 8px 0 8px 16px;
}

.notFound {
  :global {
    .ant-result-title {
      color: #ffffff;
    }

    .ant-result-subtitle {
      color: #ffffff;
    }
  }
}

.textLayer {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
}

.dangerouslyStyles {
  line-height: 1.5;
}

.forecastCollapse > .ant-collapse-item {
  border-radius: unset;
  border-bottom: 1px #e8e8e8 solid;
}

.forecastCollapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0;
  padding-right: 0;
}

.forecastCollapse .ant-collapse-item:last-child {
  border-radius: unset;
}

.ant-picker-calendar {
  width: 100%;
}

.svgFill svg {
  fill: #ff626e;
}

.links {
  a {
    color: #1c6ab1;
    text-decoration: underline;
  }
}

.mapDefault {
  margin: 1px 2px;
  border: 1px solid transparent;
  width: 100%;
  background: #F6F5F2;
  text-align: left;
  box-shadow: none;
  cursor: pointer !important;
  z-index: 100;
}

.mapDefault:hover {
  border: 1px solid;
}

.mapDefault.active {
  border: 1px solid #F6F5F2;
  background: #dceaff;
}

.defaultIcons > svg {
  width: 100%;
  height: 100%;
}


.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow {
  -moz-appearance: textfield;
}

.activeMaterial {
  background-color: #5bafff;
  border-radius: 5px;
}

.activeDisaster {
  color: #1E4161;
  font-weight: 600;
}

.leaflet-popup p {
  /*font-size: 13px;*/
}

.typeTitle {
  font-weight: bold;
  font-size: 16px !important;
}
